import React, { useState, useEffect } from 'react';
import SavingsSummary from './SavingsSummary';
import Button from 'components/shared/button';
import styles from './BeginSavingsTarget.module.css';
import { appAxios } from 'api/axios';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'components/shared/input-field';
import { OptionType, SavingsFormData } from '../../../../types';

type BeginSavingsProps = {
  goalName: string;
  targetAmount?: string;
  savingCategory?: string;
  frequency?: string;
};
interface BeginSavingsTargetProps {
  handleNext: () => void;
  formValues: BeginSavingsProps;
  setFormValues: (data: BeginSavingsProps) => void;
}
function BeginSavingsTarget({
  handleNext,
  formValues,
  setFormValues,
}: BeginSavingsTargetProps) {
  const [dropdownOptions, setDropdownOptions] = useState('');
  const [dropdownSaveOptions, setDropdownSaveOptions] = useState('');
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<OptionType[]>([]);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: yup.object({
      goalName: yup.string().required('Title is required'),
      savingCategory: yup.string().required('Category is required'),
      targetAmount: yup.string().required('Amount is required'),
    }),
    onSubmit: (values) => {
      // Prevent default form submission
      // if (handleNext) {
      //   handleNext(values);
      // }
      setFormValues(values);
    },
  });

  useEffect(() => {
    setFormValues(formik.values);
  }, [formik.values]);

  const saveOptions: OptionType[] = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  const handleSelectChange = (selectedOption: OptionType | null) => {
    setDropdownOptions(selectedOption!.value);
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(`/get-saving-category`);
        if (data && data.statusCode === 200) {
          const formattedOptions = data.data.map((category: any) => ({
            label: category.name,
            value: category.id,
          }));
          setCategoryOptions(formattedOptions);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getCategories();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    const plainValue = value.replace(/,/g, '');
    if (name === `targetAmount` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`targetAmount`, formattedValue);
      formik.handleChange(e);
    } else {
      formik.handleChange(e);
    }

    // clearErrors(name);
  };

  const formatWithCommas = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <section className="">
      <div className="flex flex-col gap-y-[10px] mb-[30px]">
        {/* <Link to="/" className="flex gap-x-2 text-[#EFA005]">
          <ArrowLeft2 /> Back
        </Link> */}
        <div className="flex flex-col lg:flex-row justify-between items-center ">
          <div className="mb-4 lg:mb-0">
            <h1 className="font-semibold">
              Create a JompReserve Target Savings
            </h1>
            <p>
              Set up a new savings target and get paid everyday (@ 12% interest
              P.A) to reach your goals faster
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-x-[50px]">
        <main className=" flex-1 bg-white rounded-md px-[15px] py-[20px] lg:w-[60%]">
          <header className="flex flex-col gap-y-[3px] mb-[30px]">
            <h1 className="font-semibold text-[20px]">Start Savings</h1>
            <p>Start a JompVault target new savings.</p>
            <div className="h-[1px] bg-gray-3 mt-[5px]"></div>
          </header>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-y-[20px]"
          >
            <div>
              <InputField
                label="Target Title"
                type="text"
                name="goalName"
                placeholder="e.g Traveling to zanzibar"
                value={formik.values.goalName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.goalName}
              />
            </div>
            <div>
              <label htmlFor="">Select a Category</label>
              <Select
                options={categoryOptions}
                isLoading={loading}
                placeholder="Select"
                onChange={(option) =>
                  formik.setFieldValue('savingCategory', option?.label)
                }
                classNamePrefix="select"
              />
              {formik.touched.savingCategory &&
                formik.errors.savingCategory && (
                  <p className="text-red-500">{formik.errors.savingCategory}</p>
                )}
            </div>

            <div>
              <InputField
                label="Set Overall Target Amount"
                type="text"
                name="targetAmount"
                placeholder="e.g 50000000"
                value={formatWithCommas(formik.values.targetAmount || '')}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                error={formik.errors.targetAmount}
              />
            </div>
            <div>
              <label htmlFor="">How will you prefer to save?</label>
              <Select
                options={saveOptions}
                onChange={(option) =>
                  formik.setFieldValue('frequency', option?.value)
                }
                className="basic-multi-select mb-3 min-w-[300px]"
                placeholder="Select option"
                classNamePrefix="select"
              />
              {formik.touched.frequency && formik.errors.frequency && (
                <p className="text-red-500">{formik.errors.frequency}</p>
              )}
            </div>
            <Button
              type="button"
              onClick={() => handleNext()}
              className="bg-[#EFA005] text-white ml-auto px-12 py-2 text-sm rounded-md"
            >
              Proceed
            </Button>
          </form>
        </main>

        <SavingsSummary formValues={formValues} />
      </div>
    </section>
  );
}

export default BeginSavingsTarget;
