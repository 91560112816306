import { Eye, EyeOff } from 'lucide-react';

export const Icon = ({
  shown,
  className,
  size = 16,
  onClick,
}: {
  shown: boolean;
  className: string;
  size?: number;
  onClick: () => void;
}) => {
  return (
    <button onClick={onClick} type='button'>
      {!shown ? (
        <Eye size={size} className={className} />
      ) : (
        <EyeOff size={size} className={className} />
      )}
    </button>
  );
};
