import React from 'react';
import ReactModal from 'react-modal';
import Button from '../button';
import styles from './style.module.css';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaChevronRight } from 'react-icons/fa';
import { ChevronRight, X } from 'lucide-react';
import { CloseCircle, CloseSquare } from 'iconsax-react';
import { howJompWorks } from '../../../pages/user/dashboard/savings/allProps';
import { Link } from 'react-router-dom';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  btnText: string;
  content: React.ReactNode;
  onConfirm?: () => void;
  onAction?: () => void;
};

const StartSavingModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  subtitle,
  content,
  btnText,
  onConfirm,
  onAction,
  ...rest
}) => {
  const navigate = useNavigate();


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-y-auto bg-gray-500 bg-opacity-50 px-5">
      <div className="relative p-6 h-[700px] overflow-y-auto  w-full max-w-xl bg-white rounded-lg shadow-lg">
        <div className="flex flex-col gap-3 mt-3">
          <div className="flex flex-col items-center justify-center text-center mt-4">
            <img src="/svgs/banner.svg" alt="jomp_banner" className="" />
          </div>
          <div className=" bg-[#F0EDFF] font-semibold p-1 text-center my-2">
            <h2>How JompVault Works</h2>
          </div>
          <div className="flex flex-col gap-y-3">
            {howJompWorks.map((data: any) => (
              <div
                className="flex gap-x-6 text-[12px] px-4 py-2 text-left"
                style={{ background: `${data.bg}` }}
              >
                <img src={data.image} alt="photo_of_a_vault" />
                <div className="flex flex-col leading-[13px]">
                  <div>
                    <h3 className="font-semibold">{data.title}</h3>
                    <p className="text-[12px]">{data.description}</p>
                  </div>
                  <ul className="text-[10px]">
                    {data.firstGoal !== '' ? (
                      <li>
                        <span className="font-semibold mr-2">
                          {data.firstGoal.split(' ').slice(0, 2).join(' ')}
                        </span>
                        <span>
                          {data.firstGoal.split(' ').slice(2).join(' ')}
                        </span>
                      </li>
                    ) : (
                      ''
                    )}
                    {data.secondGoal !== '' ? (
                      <li>
                        <span className="font-semibold mr-2">
                          {data.secondGoal.split(' ').slice(0, 2).join(' ')}
                        </span>
                        <span>
                          {data.secondGoal.split(' ').slice(2).join(' ')}
                        </span>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center w-[100%] my-6">
            <button
              onClick={onAction}
              className="bg-[#EFA005] text-white rounded-md text-center px-[7rem] py-2.5"
            >
              Start Saving
            </button>
          </div>
        </div>

        <CloseSquare
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
        />
      </div>
    </div>
  );
};

export default StartSavingModal;
