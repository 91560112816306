import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StartSavingModal from 'components/shared/modal/StartSavingsModal';
import SavingsForms from 'components/user/SavingsForm';

function NoSavingsYet() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showSavingsForm, setShowSavingsForm] = useState<boolean>(false);

  const handleStartSavings = () => {
    setIsModalOpen(false); // Close the modal
    setShowSavingsForm(true); // Show the SavingsForms component
  };

  if (showSavingsForm) {
    return <SavingsForms />;
  }

  return (
    <div className="flex flex-col justify-center items-center gap-y-2">
      <figure>
        <img src="/svgs/saveMoney.svg" alt="piggy_ban_icon" />
      </figure>
      <div className="flex flex-col gap-y-2 justify-center items-center mb-4 lg:mb-0">
        <h1 className="text-l font-bold">No Savings Yet!</h1>
        <p>Reach your individual savings goals with 12 p.a </p>
      </div>
      {/* <button
        className="py-2 px-4 bg-[#EFA005] text-white hover:bg-[#e9b959] rounded-md"
        onClick={() => setIsModalOpen(true)}
      >
        Start New Savings
      </button> */}

      <StartSavingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title=""
        subtitle=""
        btnText="Proceed"
        content={
          <p>
            {/* Your email address has been successfully verified, proceed to
            complete your onboarding details. */}
          </p>
        }
        onAction={handleStartSavings}
      />
    </div>
  );
}

export default NoSavingsYet;
