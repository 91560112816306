import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { RoutePaths } from "../route-paths";
import { ProtectedVendorRoute, PrivateVendorRoute } from "../utils";

const GetStartedPage = lazy(() => import("../../pages/vendor/auth/GetStarted"));
const VendorEmailVerificationPage = lazy(
	() => import("../../pages/vendor/auth/EmailVerification")
);
const VendorSignupPage = lazy(() => import("../../pages/vendor/auth/SignUp"));
const VendorLoginPage = lazy(() => import("../../pages/vendor/auth/Login"));
const UserLoginPage = lazy(() => import("../../pages/user/auth/Login"));
const VendorForgotPasswordPage = lazy(
	() => import("../../pages/vendor/auth/ForgotPassword")
);
const VendorResetPasswordPage = lazy(
	() => import("../../pages/vendor/auth/ResetPassword")
);

const VendorBusinessInfoPage = lazy(
  () => import('../../pages/vendor/auth/compliance/BusinessInfo')
);

const VendorServicePage = lazy(
  () => import('../../pages/vendor/dashboard/services')
);
const ServiceDetailsPage = lazy(
  () => import('../../pages/vendor/dashboard/services/ServiceDetails')
);

// const ChangePassword = lazy(
//   () => import('../../pages/user/schoolfees/ChangePassword')
// );

const vendorAuthRoutes: RouteObject[] = [
  {
    path: RoutePaths.LOGIN,
    element: (
      <ProtectedVendorRoute>
        <UserLoginPage />
      </ProtectedVendorRoute>
    ),
  },
  {
    path: RoutePaths.GETSTARTED,
    element: (
      <ProtectedVendorRoute>
        <GetStartedPage />
      </ProtectedVendorRoute>
    ),
  },
  {
    path: RoutePaths.LOGIN,
    element: (
      <ProtectedVendorRoute>
        <VendorLoginPage />
      </ProtectedVendorRoute>
    ),
  },
  {
    path: RoutePaths.PROVIDER_REGISTER,
    element: (
      <ProtectedVendorRoute>
        <VendorSignupPage />
      </ProtectedVendorRoute>
    ),
  },
  {
    path: RoutePaths.PROVIDER_EMAIL_VERIFICATION,
    element: (
      <ProtectedVendorRoute>
        <VendorEmailVerificationPage />
      </ProtectedVendorRoute>
    ),
  },
  {
    path: RoutePaths.PROVIDER_FORGOT_PASSWORD,
    element: (
      <ProtectedVendorRoute>
        <VendorForgotPasswordPage />
      </ProtectedVendorRoute>
    ),
  },
  {
    path: RoutePaths.PROVIDER_RESET_PASSWORD,
    element: (
      <ProtectedVendorRoute>
        <VendorResetPasswordPage />
      </ProtectedVendorRoute>
    ),
  },
  // {
  //   path: RoutePaths.CHANGE_PASSWORD,
  //   element: (
  //     <ProtectedVendorRoute>
  //       <ChangePassword />
  //     </ProtectedVendorRoute>
  //   ),
  // },
  // {
  //   path: RoutePaths.PROVIDER_COMPLIANCE,
  //   element: (
  //     <PrivateVendorRoute>
  //       <VendorBusinessInfoPage />
  //     </PrivateVendorRoute>
  //   ),
  // },
  {
    path: RoutePaths.PROVIDER_SERVICES,
    element: (
      <PrivateVendorRoute>
        <VendorServicePage />
      </PrivateVendorRoute>
    ),
  },
  {
    path: RoutePaths.SERVICE_DETAILS, // Add this new route
    element: (
      <PrivateVendorRoute>
        <ServiceDetailsPage />
      </PrivateVendorRoute>
    ),
  },
];

export default vendorAuthRoutes;
