import React, { useState, useEffect } from 'react';
import SavingsSummary from './SavingsSummary';
import { appAxios } from 'api/axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'components/shared/input-field';
import Select from 'react-select';
import styles from './FinishSavingsTarget.module.css';
import Button from 'components/shared/button';
import { OptionType } from '../../../../types';
import { useNavigate } from 'react-router-dom';
import CardFormModal from 'components/shared/modal/CardForm';

interface FinishSavingsProps {
  monthlyContribution?: string;
  startDate?: string;
  endDate?: string;
  preferredTime?: string;
  autoSave?: boolean;
  status?: string;
  savingSource?: string;
  interestTagentSaving?: boolean;
  targetBreakBeforeEndDate?: boolean;
  savingsTime?: string;
  cardDetails?: {
    cardNumber: string;
    expiryMonth: string;
    expiry_year: string;
    cvv: string;
  };
}
interface FinishSavingsTargetProps {
  handleBack: () => void;
  handleNext: () => void;
  handleDataSubmit: () => void;
  formValues: FinishSavingsProps;
  setFormValues: (data: FinishSavingsProps) => void;
}

const validationSchema = yup.object({
  amount: yup
    .number()
    .required('Preferred amount is required')
    .typeError('Must be a number')
    .positive('Amount must be positive'),
  preferredTime: yup
    .string()
    .required('Preferred time is required')
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format'),
  startDate: yup.date().required('Start date is required'),
  endDate: yup
    .date()
    .required('Withdrawal date is required')
    .min(yup.ref('startDate'), 'Withdrawal date must be after start date'),
  autoSave: yup.boolean(),
  savingSource: yup.string().required('Saving source is required'),
});

function FinishSavingsTarget({
  handleBack,
  handleNext,
  handleDataSubmit,
  formValues,
  setFormValues,
}: FinishSavingsTargetProps) {
  const [dropdownOptions, setDropdownOptions] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [sourceOptions, setSourceOptions] = useState<OptionType[]>([]);
  const [isCardModalOpen, setCardModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values.preferredTime);
      const dataToSubmit = {
        ...values,
        savingsTime: values.preferredTime,
      };
      setFormValues(dataToSubmit);
    },
  });

  const handleCardDetailsSave = (cardDetails: any) => {
    // Update Formik's cardDetails
    formik.setFieldValue('cardDetails', cardDetails);
    setCardModalOpen(false); // Close the modal
  };

  const handleSourceChange = (selectedOption: OptionType | null) => {
    formik.setFieldValue('savingSource', selectedOption?.label || '');
    if (selectedOption?.label === 'Card') {
      setCardModalOpen(true); // Open the modal when "Card" is selected
    }
  };

  useEffect(() => {
    const getPaymentSource = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(`/get-saving-source`);
        if (data && data.statusCode === 200) {
          const formattedOptions = data.data.map((source: any) => ({
            label: source.name,
            value: source.id,
          }));
          setSourceOptions(formattedOptions);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getPaymentSource();
  }, []);

  const formatWithCommas = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    const plainValue = value.replace(/,/g, '');
    if (name === `monthlyContribution` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`monthlyContribution`, formattedValue);
      formik.handleChange(e);
    } else {
      formik.handleChange(e);
    }

    // clearErrors(name);
  };

  useEffect(() => {
    setFormValues(formik.values);
  }, [formik.values]);

  return (
    <section>
      <div className="flex flex-col gap-y-[10px] mb-[30px]">
        <div className="flex flex-col lg:flex-row justify-between items-center ">
          <div className="mb-4 lg:mb-0">
            <h1 className="font-semibold">
              Create a JompReserve Target Savings
            </h1>
            <p>
              Set up a new savings target and get paid everyday (@ 12% interest
              P.A) to reach your goals faster
            </p>
          </div>
        </div>
      </div>
      {/* ********************************** */}
      <div className="flex flex-col-reverse gap-y-[20px] lg:flex-row lg:gap-x-[50px]">
        <main className=" flex-1 bg-white rounded-md px-[15px] py-[20px] lg:w-[60%]">
          <header className="flex flex-col gap-y-[3px] mb-[20px]">
            <h1 className="font-semibold text-[20px]">Finish Setting Up</h1>
            <p>Finalize your target settings</p>
            <div className="h-[1px] bg-gray-3 mt-[10px] mb-[25px]"></div>
          </header>
          <form action="" className="flex flex-col gap-y-[20px]">
            <div>
              <InputField
                label="Preferred amount to save on a basis"
                type="text"
                name="monthlyContribution"
                placeholder="e.g 200000"
                value={formatWithCommas(
                  formik.values.monthlyContribution || ''
                )}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                error={formik.errors.monthlyContribution}
              />
            </div>
            <div>
              <label htmlFor="" className={styles.label}>
                Preferred Time
              </label>
              <input
                type="time"
                name="preferredTime"
                className="w-full border border-gray-300 rounded-md px-2 py-2 dark:focus:border-[#EFA005] outline-none"
                placeholder="e.g 4:30 am"
                value={formik.values.preferredTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.preferredTime && formik.errors.preferredTime ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.preferredTime}
                </div>
              ) : null}
            </div>
            <div className="flex  gap-y-[20px] flex-col lg:flex-row lg:gap-x-[20px]">
              <div className="flex-1">
                <label htmlFor="" className={styles.label}>
                  Set Start Date
                </label>
                <div className="w-full flex justify-between border border-gray-300 rounded-md px-[10px] py-1 dark:focus:border-[#EFA005] outline-none items-center gap-x-[15px]">
                  <input
                    type="date"
                    name="startDate"
                    className="border-none outline-none px-2 py-1 w-full"
                    placeholder="e.g dd/mm/yy"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.startDate}
                  </div>
                ) : null}
              </div>
              <div className="flex-1">
                <label htmlFor="" className={styles.label}>
                  Set End Date
                </label>
                <div className="w-full flex justify-between border border-gray-300 rounded-md px-[10px] py-1 dark:focus:border-[#EFA005] outline-none items-center gap-x-[10px]">
                  <input
                    type="date"
                    name="endDate"
                    className="border-none outline-none px-2 py-1 w-full"
                    placeholder="e.g dd/mm/yy"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.endDate && formik.errors.endDate ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.endDate}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <figure className="flex bg-[#F1F0FB] text-[10px] items-center gap-x-[10px] px-[7px] py-[10px] rounded-md">
                <img
                  src="/svgs/info-circle.svg"
                  alt="alert_icon"
                  className="h-[19.93px] w-[19.93px]"
                />
                <figcaption>
                  JompVault only allows 45days-90days, please make sure your
                  date selection falls within that range
                </figcaption>
              </figure>
            </div>
            {/********************************** radio Buttons Start******************************/}
            <div className="flex flex-col gap-y-[2px]">
              <label htmlFor="" className={styles.label}>
                Do you want to auto-save?
              </label>
              {/* Auto save */}
              <div className="flex items-center gap-x-[20px]">
                <div className="flex justify-between items-center border border-gray-300 rounded-md px-[10px] py-1 dark:focus:border-[#EFA005] outline-none flex-1">
                  <p className="text-[12px]">Yes</p>
                  <div className={styles.radioItem}>
                    <input
                      type="radio"
                      name="autoSave"
                      id="autoSave"
                      checked={formik.values.autoSave}
                      onChange={() => formik.setFieldValue('autoSave', true)}
                      className={styles.input}
                    />
                    <label
                      htmlFor="autoSave"
                      className={styles.labelItem}
                    ></label>
                  </div>
                </div>

                <div className="flex justify-between items-center border border-gray-300 rounded-md px-[10px] py-1 dark:focus:border-[#EFA005] outline-none flex-1">
                  <p className="text-[12px]">No</p>
                  <div className={styles.radioItem}>
                    <input
                      type="radio"
                      name="autoSave"
                      id="noSave"
                      checked={!formik.values.autoSave}
                      onChange={() => formik.setFieldValue('autoSave', false)}
                      className={styles.input}
                    />
                    <label
                      htmlFor="noSave"
                      className={styles.labelItem}
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            {/********************************* Radio Buttons End ****************************************** */}
            <div>
              <label htmlFor="">Select a primary source</label>

              <Select
                options={sourceOptions}
                isLoading={loading}
                onChange={handleSourceChange}
                className="basic-multi-select mb-3 min-w-[300px]"
                placeholder="Select source"
                classNamePrefix="select"
              />
              {formik.touched.savingSource && formik.errors.savingSource && (
                <p className="text-xs text-red-500">
                  {formik.errors.savingSource}
                </p>
              )}
            </div>

            <hr />
            {/* Agreements */}
            <div className="flex flex-col gap-y-2 mt-2">
              <div className="flex gap-2 items-center">
                <div>
                  <input
                    type="checkbox"
                    name="interestTagentSaving"
                    id="interestTagentSaving"
                    checked={formik.values.interestTagentSaving}
                    onChange={formik.handleChange}
                    className={styles.inputOne}
                  />
                  <label
                    htmlFor="interestTagentSaving"
                    className={styles.switchOne}
                  ></label>
                </div>
                <p className="text-[10px] col-span-9">
                  I hereby agree that I will forfeit the interest accrued on
                  this target savings if I fail to meet this target amount of
                  (₦3,000,000.00) by the set withdrawal date.
                </p>
              </div>

              <div className="flex gap-2 items-center">
                <div className="">
                  <input
                    type="checkbox"
                    name="targetBreakBeforeEndDate"
                    id="targetBreakBeforeEndDate"
                    checked={formik.values.targetBreakBeforeEndDate}
                    onChange={formik.handleChange}
                    className={styles.inputTwo}
                  />
                  <label
                    htmlFor="targetBreakBeforeEndDate"
                    className={styles.switchTwo}
                  ></label>
                </div>
                <p className="text-[10px] col-span-9">
                  I hereby agree to this: "If you break this target before the
                  withdrawal date, you will loose all the interest accrued and
                  bear the 1% payment gateway charge for processing your
                  deposits into this target."
                </p>
              </div>
            </div>
            <div className="h-[1px] bg-gray-3 mt-[10px] mb-[25px]"></div>

            <div className="flex justify-end gap-x-[20px]">
              <button
                onClick={handleBack}
                className="border border-[#EFA005] px-12 py-2 rounded-[8px] outline-none focus:shadow-md text-[#EFA005]"
              >
                Back
              </button>
              <Button
                type="button"
                className="outline-none"
                onClick={handleNext}
              >
                Create Savings
              </Button>
            </div>
          </form>
        </main>

        <SavingsSummary formValues={formValues} />
      </div>

      {/* Render the CardFormModal */}
      {isCardModalOpen && (
        <CardFormModal
          isOpen={isCardModalOpen}
          onClose={() => setCardModalOpen(false)}
          onSave={handleCardDetailsSave} // Save card details
        />
      )}
    </section>
  );
}

export default FinishSavingsTarget;
