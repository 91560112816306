import { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from 'components/shared/button';
import InputField from 'components/shared/input-field';
import Modal from 'components/shared/modal';

const cardValidationSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .test(
      'exact-16-digits',
      'Card number must be exactly 16 digits',
      (value) => {
        const digitsOnly = value ? value.replace(/\s+/g, '') : ''; // Handle undefined case
        return digitsOnly.length === 16;
      }
    )
    .required('Card number is required'),
  expiryMonth: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])$/, 'Enter a valid month (01-12)')
    .required('Expiry month is required'),
  expiry_year: yup
    .string()
    .matches(/^\d{4}$/, 'Enter a valid 4-digit year')
    .required('Expiry year is required'),
  cvv: yup
    .string()
    .matches(/^\d{3}$/, 'CVV must be 3')
    .required('CVV is required'),
});

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (cardDetails: any) => void;
};

const CardFormModal: FC<Props> = ({ isOpen, onClose, onSave }) => {
  const formik = useFormik({
    initialValues: {
      cardNumber: '',
      expiryMonth: '',
      expiry_year: '',
      cvv: '',
    },
    validationSchema: cardValidationSchema,
    onSubmit: (values) => {
      console.log('Card Details Submitted:', values);
      // Handle form submission logic here

      onSave(values);
    },
  });

  // Function to add space after every 4 digits in card number
  const handleCardNumberChange = (e: any) => {
    const { value } = e.target;
    const digitsOnly = value.replace(/\D/g, '');
    if (digitsOnly.length > 16) return; // Prevent more than 16 digits
    const formattedValue = digitsOnly.replace(/(\d{4})(?=\d)/g, '$1 ');
    formik.setFieldValue('cardNumber', formattedValue);
  };

  // Function to restrict expiry month to 2 digits
  const handleExpiryMonthChange = (e: any) => {
    const { value } = e.target;
    if (value.length > 2) return; // Prevent more than 2 digits
    formik.setFieldValue('expiryMonth', value);
  };

  // Function to restrict expiry year to 4 digits
  const handleExpiryYearChange = (e: any) => {
    const { value } = e.target;
    if (value.length > 4) return; // Prevent more than 4 digits
    formik.setFieldValue('expiry_year', value);
  };

  // Function to restrict CVV to 3 digits
  const handleCvvChange = (e: any) => {
    const { value } = e.target;
    if (value.length > 3) return; // Prevent more than 3 digits
    formik.setFieldValue('cvv', value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Enter Card Details"
      subtitle="Provide your card information securely"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <InputField
          name="cardNumber"
          label="Card Number"
          placeholder="Enter your 16-digit card number"
          value={formik.values.cardNumber}
          onChange={handleCardNumberChange}
          onBlur={formik.handleBlur}
          error={formik.touched.cardNumber ? formik.errors.cardNumber : ''}
          maxLength={19}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
          <div className="sm:col-span-1">
            <InputField
              name="expiryMonth"
              label="Expiry Month"
              placeholder="MM"
              value={formik.values.expiryMonth}
              onChange={handleExpiryMonthChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.expiryMonth ? formik.errors.expiryMonth : ''
              }
            />
          </div>
          <div className="sm:col-span-1">
            <InputField
              name="expiry_year"
              label="Expiry Year"
              placeholder="YYYY"
              value={formik.values.expiry_year}
              onChange={handleExpiryYearChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.expiry_year ? formik.errors.expiry_year : ''
              }
            />
          </div>
        </div>
        <InputField
          name="cvv"
          label="CVV"
          placeholder="Enter your CVV"
          type="password"
          value={formik.values.cvv}
          onChange={handleCvvChange}
          onBlur={formik.handleBlur}
          error={formik.touched.cvv ? formik.errors.cvv : ''}
        />
        <div className="flex justify-end gap-3 mt-4">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" loading={false}>
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CardFormModal;
