import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ArrowLeft2 } from 'iconsax-react';
import ViewAmountSaved from './ViewAmountSaved';
import { viewAmount } from './allProps';
import NoSavingsYet from './NoSavingsYet';
import SavingsProgressNav from './SavingsProgressNav';
import StartSavingModal from '../../../../components/shared/modal/StartSavingsModal';
import SavingsForms from 'components/user/SavingsForm';
import { appAxios } from 'api/axios';
import { getTokenDetails } from 'functions/userSession';
import { decodeToken } from 'react-jwt';
import { TokenType } from 'types/jwt';
import OngoingItemsSelectedForSaving from './OngoingItemsSelectedForSaving';
import SavedItems from './SavedItems';

function HowJomptVaultWorks() {
  const token = getTokenDetails();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const decodedToken = decodeToken<TokenType>(token!);
  const customerId = decodedToken?.customerId;

  const [allSavings, setAllSavings] = useState<[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showSavingsForm, setShowSavingsForm] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'Ongoing' | 'Completed'>(
    'Ongoing'
  ); // Define type for activeTab

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous route
  };

  const handleStartSavings = () => {
    setIsModalOpen(false); // Close the modal
    setShowSavingsForm(true); // Show the SavingsForms component
  };

  const handleTabChange = (tab: 'Ongoing' | 'Completed') => {
    setActiveTab(tab); // Update activeTab
  };

  useEffect(() => {
    const getAllSavings = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(
          `get-customer-savings?CustomerId=${customerId}`
        );
        if (data && data.statusCode === 200) {
          setAllSavings(data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getAllSavings();
  }, [activeTab, activeTab === 'Ongoing']);

  return (
    <div className="container mx-auto mt-5 mb-28">
      <div className="flex flex-col gap-y-4">
        <button
          onClick={handleGoBack}
          className="flex items-center gap-2 text-[#EFA005]"
        >
          <ArrowLeft2 />
          Back
        </button>
        {showSavingsForm ? (
          <SavingsForms />
        ) : (
          //when the SavingsForms displays everything here is hidden
          <>
            <div className="flex flex-col md:flex-row lg:flex-row justify-between items-center ">
              <div className="mb-4 lg:mb-0">
                <h1 className="text-2xl font-semibold">Savings</h1>
                <p>Let's see how well you are doing with Jomp Savings</p>
              </div>

              <button
                className="bg-[#EFA005] text-white rounded-md px-4 py-2 "
                onClick={() => setIsModalOpen(true)}
              >
                Start New Savings
              </button>
            </div>

            {/* <ViewAmountSaved viewAmount={viewAmount} /> */}

            <div className="mt-2 mb-4 bg-white py-6">
              <div className="flex flex-col lg:flex-row lg:gap-10 items-center lg:pb-0 pb-4 px-4">
                <div className="flex lg:gap-10 lg:flex-row flex-col lg:items-center">
                  <div>
                    <figure className="rounded-md m-x-4 ml-4 h-[100%]">
                      <img src="/svgs/Info.svg" alt="" />
                    </figure>
                  </div>
                  <div className="flex-1 my-4">
                    <h2 className="font-bold mb-2 ">How Jomp Savings Works</h2>
                    <p className="text-sm">
                      Planning for something special in the next month or two ?
                      Whether it's a grooming appointment, a saloon, or car
                      repairs, JompVault helps you save specifically for these
                      short-term goals
                    </p>
                  </div>
                </div>
                {/* <Link
                  to="/"
                  className="border-2 border-solid border-[#EFA005] px-4 py-1 rounded-md shrink-0 mb-[-20px] lg:mb-0"
                >
                  Learn More
                </Link> */}
              </div>
            </div>

            <div className="flex flex-col gap-8">
              <SavingsProgressNav
                activeTab={activeTab}
                onTabChange={handleTabChange}
              />
              {activeTab === 'Ongoing' && allSavings.length > 0 ? (
                <SavedItems allSavings={allSavings} />
              ) : (
                <NoSavingsYet />
              )}
              {/* {allSavings.length === 0 && <NoSavingsYet />} */}
            </div>
          </>
        )}
      </div>
      <StartSavingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title=""
        subtitle=""
        btnText="Proceed"
        content={
          <p>
            {/* Your email address has been successfully verified, proceed to
            complete your onboarding details. */}
          </p>
        }
        onAction={handleStartSavings}
      />
    </div>
  );
}

export default HowJomptVaultWorks;
