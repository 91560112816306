// // import React from 'react';
// // import { NavLink } from 'react-router-dom';

// // function SavingsProgressNav() {
// //   return (
// //     <div>
// //       <ul className="flex gap-x-8 border-b-4 border-[#F1F0FB]">
// //         <li>
// //           <NavLink to="/">Ongoing</NavLink>
// //         </li>
// //         <li>
// //           <NavLink to="/">Completed</NavLink>
// //         </li>
// //       </ul>
// //     </div>
// //   );
// // }

// // export default SavingsProgressNav;

// // SavingsProgressNav.js
// import React from 'react';
// import { NavLink } from 'react-router-dom';

// interface Props {
//   activeTab: 'ongoing' | 'completed';
//   onTabChange: (tab: 'ongoing' | 'completed') => void;
// }

// function SavingsProgressNav({ activeTab, onTabChange }: Props) {
//   return (
//     <div>
//       <ul className="flex gap-x-8 border-b-4 border-[#F1F0FB]">
//         <li>
//           <NavLink
//             to="/ongoing"
//             className={
//               activeTab === 'ongoing' ? 'text-[#EFA005] font-bold' : ''
//             }
//             onClick={() => onTabChange('ongoing')}
//           >
//             Ongoing
//           </NavLink>
//         </li>
//         <li>
//           <NavLink
//             to="/completed"
//             className={
//               activeTab === 'completed' ? 'text-[#EFA005] font-bold' : ''
//             }
//             onClick={() => onTabChange('completed')}
//           >
//             Completed
//           </NavLink>
//         </li>
//       </ul>
//     </div>
//   );
// }

// export default SavingsProgressNav;

import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  activeTab: 'Ongoing' | 'Completed';
  onTabChange: (tab: 'Ongoing' | 'Completed') => void;
}

function SavingsProgressNav({ activeTab, onTabChange }: Props) {
  return (
    <div>
      <ul className="flex gap-x-8 border-b-4 border-[#F1F0FB]">
        <li>
          <button
            className={`pb-2 ${
              activeTab === 'Ongoing'
                ? 'border-b-4 border-[#EFA005] font-bold'
                : 'text-gray-500'
            } focus:outline-none`}
            onClick={() => onTabChange('Ongoing')}
          >
            Ongoing
          </button>
        </li>
        <li>
          <button
            className={`pb-2 ${
              activeTab === 'Completed'
                ? 'border-b-4 border-[#EFA005] font-bold'
                : 'text-gray-500'
            } focus:outline-none`}
            onClick={() => onTabChange('Completed')}
          >
            Completed
          </button>
        </li>
      </ul>
    </div>
  );
}

export default SavingsProgressNav;
