import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutePaths } from '../route-paths';
const LandingPage = lazy(() => import('../../pages'));
const SchoolfeesPage = lazy(() => import('../../pages/user/schoolfees'));
const HouseRentPage = lazy(() => import('../../pages/user/rent'));
const SchoolfeePage = lazy(
  () => import('../../pages/user/InstitutionSchoolFees')
);
const TermsConditions = lazy(() => import('../../pages/termsConditions'));
const Privacy = lazy(() => import('../../pages/privacy'));
const SchoolFeeLandingPage = lazy(
  () => import('../../pages/SchoolFeeLandingPage')
);
const RentLandingPage = lazy(() => import('../../pages/RentLandingPage'));
const TransportLandingPage = lazy(
  () => import('../../pages/TransportLandingPage')
);

export const homeRoutes: RouteObject[] = [
  {
    path: RoutePaths.HOMEPAGE,
    element: <LandingPage />,
  },
  {
    path: RoutePaths.TERMS_CONDITIONS,
    element: <TermsConditions />,
  },
  {
    path: RoutePaths.PRIVACY,
    element: <Privacy />,
  },
  {
    path: RoutePaths.SCHOOL_FEES,
    element: <SchoolfeesPage />,
  },
  {
    path: RoutePaths.RENT,
    element: <HouseRentPage />,
  },
  {
    path: RoutePaths.INSTITUTION_SCHOOLFEES,
    element: <SchoolfeePage />,
  },
  { path: RoutePaths.SCHOOLFEES_GETSTARTED, element: <SchoolFeeLandingPage /> },
  { path: RoutePaths.RENT_GETSTARTED, element: <RentLandingPage /> },
  { path: RoutePaths.TRANSPORT_GETSTARTED, element: <TransportLandingPage /> },
];
