import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes/route-paths';
import { itemsOnYourVault } from './allProps';
import { formatToNaira } from 'functions/currencyConveter';

interface SavingsItem {
  id: string;
  goalName: string;
  targetAmount: number;
  savedAmount: number;
  startDate: string;
  endDate: string;
  duration: number;
  isAchieved: boolean;
  createdAt: string;
  imageUrl: string;
}

interface SavedItemsProps {
  allSavings: SavingsItem[];
}

function SavedItems({ allSavings }: SavedItemsProps) {
  return (
    <ul className="flex flex-col gap-y-[10px]">
      {allSavings.map((item) => {
        return (
          <EachItem
            id={item.id}
            title={item.goalName}
            value={formatToNaira(item.targetAmount)}
            maturationDuration={`Duration: ${item.duration} months`}
            maturationIcon={item?.imageUrl}
            progress={
              item.isAchieved
                ? '/svgs/canvasZero.svg'
                : '/svgs/canvasHundred.svg'
            }
          />
        );
      })}
    </ul>
  );
}

interface EachItemProps {
  title: string;
  value: string;
  maturationIcon: string;
  maturationDuration: string;
  progress: string;
  id: string;
}

function EachItem({
  title,
  value,
  maturationIcon,
  maturationDuration,
  progress,
  id,
}: EachItemProps) {
  const navigate = useNavigate();

  const handleRedirect = (id: string) => {
    navigate(`${RoutePaths.USER_SAVINGS_JOMPVAULT}/${id}`, {
      state: { id },
    });
  };

  return (
    <li
      key={id}
      onClick={() => handleRedirect(id)}
      className="flex justify-between gap-x-[15px] cursor-pointer bg-[#FFF] px-[8px] py-[8px] rounded-md"
    >
      <div className="flex gap-x-[15px]">
        <img src="/svgs/house.svg" alt="" />
        <div className="">
          <h1 className="text-[14px]">{title}</h1>
          <p className="font-semibold">{value}</p>
          <span className="text-gray-500">{maturationDuration}</span>
          {/* <div className="flex gap-x-[10px] mt-[8px] text-[12px]">
            <img src={maturationIcon} alt={title} />
          </div> */}
        </div>
      </div>
      {/* <img src={progress} alt="progress icon" /> */}
    </li>
  );
}

export default SavedItems;
